import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import dayjs from './plugins/day';
import Vue3Transitions from 'vue3-transitions';
import 'modern-css-reset/dist/reset.min.css';

createApp(App)
  .use(store)
  .use(dayjs)
  .use(Vue3Transitions)
  .mount('#app');
