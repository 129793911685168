<template>
  <div
    class="employee-profile-item"
  >
    <div class="preview" :style="{ left: position.left + 'px', top: position.top + 'px', width: avatarWidth + 'px', height: avatarWidth + 'px' }">
      <img
        :src="item.avatar_src"
        :alt="item.full_name"
        @click="this.showDetailsModal = true;"
      />
    </div>
    <FadeInOut entry="center" exit="center" :duration="150" mode="in-out">
      <ModalWindow
        v-if="showDetailsModal"
        @close="this.showDetailsModal = false;"
        class="employee-profile-details"
      >
        <template #header>
          <div class="employee-avatar">
            <ImageShadow v-if="!item.avatar_generated" :src="item.avatar_src" :alt="item.full_name" :width="300" />
            <img v-else :src="item.avatar_src" :alt="item.full_name" width="300" />
          </div>
          <br>
          <h3>{{ item.full_name }}</h3>
        </template>
        <template #body>
          <table>
            <tr>
              <th>UID</th>
              <td>{{ item.uid }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ item.mail }}</td>
            </tr>
            <tr>
              <th>Nickname</th>
              <td>{{ item.nickname }}</td>
            </tr>
            <tr>
              <th>Birthday</th>
              <td>{{ birthday }}</td>
            </tr>
          </table>
        </template>
        <template #footer>
          <div></div>
        </template>
      </ModalWindow>
    </FadeInOut>
  </div>
</template>

<script>
import ModalWindow from '@/components/ModalWindow';
import ImageShadow from '@/components/ImageShadow';

export default {
  name: "MapEmployeeItem",
  components: { ImageShadow, ModalWindow },
  props: {
    item: {
      type: Object,
      required: true
    },
    workplace: {
      type: Object,
      required: true
    },
    avatarSize: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      showDetailsModal: false,
    }
  },
  computed: {
    position() {
      if (!this.workplace) {
        return {
          top: 0,
          left: 0
        }
      }
      else {
        return this.workplace.position;
      }
    },
    avatarWidth() {
      const multiplier = 1.3;

      if (this.avatarSize) {
        return this.avatarSize * multiplier;
      }
      if (this.workplace) {
        return this.workplace.size.width * multiplier;
      }
      return 48;
    },
    birthday() {
      return this.$dayjs(this.item.birthday).format('DD MMMM YYYY');
    }
  },
}
</script>

<style lang="scss">
.employee-profile-item {
  .preview {
    position: absolute;
    z-index: 4;
    img {
      box-shadow: 0 0 7px #232220;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      width: 100%;
      background-color: white;
    }
  }
  .employee-profile-details {
    .employee-avatar {
      > img,
      .vue-image-shadow {
        margin: 0 auto;
      }

      > img {
        border-radius: 10%;
      }
    }
    .modal-body {
        th {
            text-align: right;
        }
        td {
            text-align: left;
        }
    }
  }
}
</style>
