<template>
  <OfficeMap />
</template>

<script>
import OfficeMap from '@/components/OfficeMap';
import $ from 'jquery';

window.$ = $;

export default {
  name: 'App',
  components: { OfficeMap },
};
</script>

<style lang="scss">

</style>
