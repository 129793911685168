<template>
  <div class="modal-mask" @click="backdropClick">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-close" @click="$emit('close')" />
        <div class="modal-header">
          <slot name="header" />
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-default-button" @click="$emit('close')">OK</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  emits: ['close'],
  mounted () {
    document.addEventListener('keydown', (e) => {
      if(e.keyCode === 27) {
        this.$emit('close');
      }
    });
  },
  methods: {
    backdropClick(event) {
      if (event.target === this.$el.querySelector('.modal-wrapper')) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  z-index: 5;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .modal-container {
      position: relative;
      max-width: 500px;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 7px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
      .modal-header {
        h3 {
          margin-top: 0;
          color: #42b983;
          text-align: center;
        }
      }
      .modal-body {
        margin: 20px 0;
      }
      .modal-close {
        position: absolute;
        top: -16px;
        right: -16px;
        background-color: #42b983;
        width: 32px;
        height: 32px;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background-color: #349368;
        }
        &:before,
        &:after {
          position: absolute;
          top: 6px;
          left: 15px;
          content: ' ';
          height: 20px;
          width: 2px;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>
